import React from "react"
import AppLayout from "../../components/app/app-components/layout"
import { motion } from "framer-motion"
import { myAnimations } from "../../components/context/framer-animations"
import PopularExams from "../../components/app/app-components/PopularExams"

const PopularExamsAll = ({ path }) => {
  return (
    <AppLayout isBackButton={true} title="Popular Exams" path={path}>
      <motion.div {...myAnimations.fadeIn} className="pb-24 m-5 space-y-10">
        <div className="flex flex-col mt-6 mb-4 space-y-2 ">
          <p className="font-base">Popular Exams</p>
          <PopularExams limit={999} />
        </div>
      </motion.div>
    </AppLayout>
  )
}

export default PopularExamsAll
